<template>
  <div class="participate">
    <div v-if="Auth == 1"> 
      <h1>Gestion du projet</h1>
      <ul>
        <li><a href="https://cloud.opci-ethnodoc.fr">Documents ressources : Cloud</a></li>
        <li>
          <a
            href="https://drive.google.com/drive/folders/1Flm8aAFNb5XCp7c4Vt2Lm9esjYZiLGif?usp=sharing"
            >Documents de travail (docs évolutifs, comptes-rendus, programmation...) : Google Drive</a
          >
        </li>
        <li>
          <a href="https://wiki.opci-ethnodoc.fr/doku.php?id=carnaval_de_nantes"
            >Production de contenus : plateforme d'écriture collaborative (Wiki)</a
          >
        </li>
        <li>
          <a href="https://forum.opci-ethnodoc.fr/viewforum.php?f=2">Forum</a>
        </li>
        <li>
          <a
            href="https://docs.google.com/forms/d/1Kcx9BMdD2j9cfQIRQfYKwX8-8ktB-QXPhboxXOveqvw/viewform?edit_requested=true"
            >Formulaire de prise de contact</a
          >
        </li>
      </ul>
    </div>
    <LoginForm v-else />
  </div>
</template>

<style>
.participate h3,
.participate ul {
  text-align: left;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  components: {
    LoginForm: () => import("../components/login.vue"),
  },
  computed: {
    ...mapState(["Auth"]),
  },
};
</script>